import * as React from 'react'
import SectionDefault from './sectionDefault'

const SectionGrid = ({ children, cols = 3, fromLg = false }) => {
  let gridClass = 'lg:grid-cols-2'

  if (cols === 3) {
    gridClass = fromLg ? 'lg:grid-cols-3' : `${gridClass} xl:grid-cols-3`
  } else if (cols === 4) {
    gridClass = fromLg ? 'lg:grid-cols-4' : `${gridClass} xl:grid-cols-4`
  }

  return (
    <SectionDefault className={`grid gap-6 grid-cols-1  ${gridClass}`}>{children}</SectionDefault>
  )
}

export default SectionGrid
