import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { previewUrl, templatePath } from '../helpers'
import Layout from '../components/layout'
import Seo from '../components/seo'
import SectionTitle from '../components/sectionTitle'
// import TemplateIcons from '../components/templateIcons'
import SectionDefault from '../components/sectionDefault'
import TemplateCard from '../components/templateCard'
import SectionGrid from '../components/sectionGrid'
import TemplateIcon from '../components/templateIcon'
import FaqItems from '../components/faqItems'
import Title from '../components/title'
import SubTitle from '../components/subTitle'
import TemplateButtons from '../components/templateButtons'
import Tags from '../components/tags'
import SectionButtons from '../components/sectionButtons'
import FreeReturnsLine from '../components/freeReturnsLine'
import TemplateTitle from '../components/title/templateTitle'
import TemplateCardMultipleImages from '../components/templateCard/multipleImages'
// import Icon from "../components/icon"
import SectionTemplateButtons from '../components/sectionTemplateButtons'
import TemplateCardWrapper from '../components/templateCardWrapper'

const TemplateScreenshots = ({ hasMultipleColors, screenshots, longTitle, href }) => (
  <>
    <SectionTitle
      title={hasMultipleColors ? 'Multiple colors' : `${screenshots.length} designs`}
      subtitle="Light and dark modes included"
    />
    <SectionGrid
      cols={hasMultipleColors ? screenshots.length + 1 : screenshots.length}
      fromLg={true}
    >
      {screenshots.map((screenshot) => (
        <TemplateCard
          key={screenshot.childImageSharp.id}
          image={screenshot}
          alt={longTitle}
          href={href}
          linkTitle={`Preview ${longTitle}`}
          padding={1}
          className={`bg-white`}
        />
      ))}
      {hasMultipleColors && (
        <TemplateCardWrapper
          href={href}
          title={`Preview ${longTitle}`}
          padding={1}
          className={`items-center justify-center flex-col text-2xl font-bold hover:text-blue-600`}
        >
          +15 More&hellip;
        </TemplateCardWrapper>
      )}
    </SectionGrid>
  </>
)

const TemplateCalloutSection = ({children}) => (
  <SectionDefault className="my-24">
    <div className="bg-white border-8 border-gray-900 rounded-3xl p-6 sm:p-12 text-center">
      {children}
    </div>
  </SectionDefault>
)

const Template = ({
  pageContext: { premiumTemplate },
  data: {
    mdx: { body, frontmatter: item },
  },
}) => {
  const screenshot = {
    image: item.screenshot.childImageSharp.gatsbyImageData.images.fallback.src,
    width: item.screenshot.childImageSharp.gatsbyImageData.width,
    height: item.screenshot.childImageSharp.gatsbyImageData.height,
  }

  let ldJson = null

  if (!item.isFree) {
    ldJson = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      sku: item.slug,
      name: item.title,
      image: [screenshot.image],
      description: `${item.name} - ${item.subtitle}. ${item.subtitle2}.`,
      brand: {
        '@type': 'Thing',
        name: 'JustBoil.me',
      },
      offers: {
        '@type': 'Offer',
        url: `https://justboil.me${templatePath(item)}`,
        priceCurrency: 'USD',
        price: item.slug === 'two' ? 39 : 49,
        seller: {
          '@type': 'Organization',
          name: 'JustBoil.me',
        },
      },
    }

    if (item.ratingValue && item.ratingCount) {
      ldJson.aggregateRating = {
        '@type': 'AggregateRating',
        ratingValue: item.ratingValue,
        ratingCount: item.ratingCount,
      }
    }
  }

  return (
    <Layout>
      <Seo
        title={item.longTitle}
        metaTitle={item.title}
        description={`${item.name} - ${item.subtitle}. ${item.subtitle2}.`}
        image={screenshot}
      >
        {ldJson && <script type="application/ld+json">{`${JSON.stringify(ldJson)}`}</script>}
      </Seo>

      <SectionDefault maxW="max-w-6xl" className="lg:flex lg:items-center lg:justify-center my-12 lg:my-24">
        <div>
          <TemplateCard
            image={item.screenshot}
            alt={item.longTitle}
            href={item.isArchived ? null : previewUrl(item)}
            linkTitle={`Preview ${item.longTitle}`}
            className="lg:max-w-lg xl:max-w-xl mx-auto bg-white"
          />
          <TemplateCardMultipleImages item={item} />
        </div>
        <TemplateTitle item={item} className="mt-12 lg:mt-0 lg:ml-24 lg:flex-1">
          {!item.isArchived && (
            <div className="mt-24">
              <SectionTemplateButtons item={item} wrap={false} />
            </div>
          )}
          {item.isArchived && (
            <div className={`mt-24 stext-center bg-gray-50 p-6 rounded-xl`}>
              <b>This template is archived.</b> Please check{' '}
              <Link to={`/`} className={`text-blue-500`}>
                other versions
              </Link>
            </div>
          )}
        </TemplateTitle>
      </SectionDefault>

      {!item.isArchived && item.screenshots && (
        <TemplateScreenshots
          hasMultipleColors={item.hasMultipleColors}
          screenshots={item.screenshots}
          longTitle={item.longTitle}
          href={previewUrl(item)}
        />
      )}

      {item.versions && (
        <>
          <SectionTitle title={`${item.versions.length} versions`} subtitle="All included" />
          <SectionGrid cols={item.versions.length} fromLg={true}>
            {item.versions.map((version) => (
              <div key={version.title} className="flex flex-col justify-center items-center">
                <TemplateIcon
                  icon={version.icon}
                  border={true}
                  wrapperLarge={true}
                  iconLarge={true}
                  className="mb-6"
                />
                <h1 className="text-2xl font-black mb-3 leading-tight">{version.title}</h1>
                <h2 className="text-lg mb-6 leading-tight">{version.subtitle}</h2>
              </div>
            ))}
          </SectionGrid>
        </>
      )}

      {item.hasAbout && (
        <>
          <SectionTitle title="About" />
          <SectionDefault>
            <div className={`prose mx-auto`}>
              <MDXRenderer>{body}</MDXRenderer>
              {item.updated && (
                <p>
                  Updated on <b>{item.updated}</b>
                </p>
              )}
              
            </div>
          </SectionDefault>
        </>
      )}

      {item.package && (
        <>
          <SectionTitle title={`Package.json`} id="package" />
          <SectionDefault>
            <div className={`prose mx-auto max-h-64 overflow-hidden relative`}>
              <MDXRenderer>{item.package.childMdx.body}</MDXRenderer>
              <div className="absolute inset-x-0 bottom-0 w-full h-20 bg-gradient-to-t from-white to-transparent"></div>
            </div>
          </SectionDefault>
          <SectionButtons>
            <TemplateButtons
              links={[
                {
                  to: templatePath(item, 'structure', 'package'),
                  label: 'Explore package',
                  style: 'buttonBlackLarge',
                },
              ]}
            />
          </SectionButtons>
        </>
      )}

      {item.structure && (
        <>
          <SectionTitle title={`File structure`} />
          <SectionDefault>
            <div className={`prose mx-auto max-h-64 overflow-hidden relative`}>
              <MDXRenderer>{item.structure.childMdx.body}</MDXRenderer>
              <div className="absolute inset-x-0 bottom-0 w-full h-20 bg-gradient-to-t from-white to-transparent"></div>
            </div>
          </SectionDefault>
          <SectionButtons>
            <TemplateButtons
              links={[
                {
                  to: templatePath(item, 'structure', 'structure'),
                  label: 'Explore structure',
                  style: 'buttonBlackLarge',
                },
              ]}
            />
          </SectionButtons>
        </>
      )}

      {(item.isFree || !item.isArchived) && (
        <>
          <SectionTitle title="FAQ" />
          <section>
            <FaqItems item={item} url={`https://justboil.me${templatePath(item)}`} />
          </section>
        </>
      )}

      {item.isFree && premiumTemplate && (
        <TemplateCalloutSection>
          <>
            <Title>Check premium</Title>

            {!premiumTemplate.subtitle2 && (
              <SubTitle>
                {premiumTemplate.title}
                <br />
                {premiumTemplate.subtitle}
              </SubTitle>
            )}
            {premiumTemplate.subtitle2 && (
              <SubTitle>
                {premiumTemplate.title}
                <br />
                {premiumTemplate.subtitle}
                <br />
                {premiumTemplate.subtitle2}
              </SubTitle>
            )}

            <div className={`mb-12`}>
              <Tags tags={premiumTemplate.tags} justify="justify-center" premium={true} />
            </div>
            <TemplateCard
              image={premiumTemplate.screenshot}
              alt={premiumTemplate.longTitle}
              to={templatePath(premiumTemplate)}
              className="mx-auto max-w-xl mb-12 p-6 border-8 border-gray-100 bg-gray-50"
            />
            <TemplateButtons
              links={[
                {
                  to: templatePath(premiumTemplate),
                  label: 'Learn more',
                  style: 'buttonDefault',
                },
                {
                  to: previewUrl(premiumTemplate),
                  label: 'Premium preview',
                  style: 'buttonOutline',
                },
              ]}
            />
            <div className="text-sm space-y-3 mt-12">
              <p>
                Premium updated on <b>{premiumTemplate.updated}</b>
              </p>
              <p>
                <FreeReturnsLine />
              </p>
              <p>Secure payment by Gumroad, Inc.</p>
            </div>
          </>
        </TemplateCalloutSection>
      )}

      {!item.isFree && !item.isArchived && (
        <TemplateCalloutSection>
          <>
            <Title>Buy now</Title>
            <SubTitle>{item.longTitle}</SubTitle>
            <SectionTemplateButtons item={item} wrap={false} />
            <div className="text-sm space-y-3 mt-12">
              <p>
                Updated on <b>{item.updated}</b>
              </p>
              <p>
                <FreeReturnsLine />
              </p>
              <p>Secure payment by Gumroad, Inc.</p>
              {item.gitHubUrls && (
                <p>
                  <b>Free samples</b> on GitHub:
                  {item.gitHubUrls.map((url) => (
                    <a
                      href={url.url}
                      key={url.url}
                      rel="noreferrer"
                      target="_blank"
                      className="ml-1 normal"
                    >
                      {url.title}
                    </a>
                  ))}
                </p>
              )}
              {!item.gitHubUrls && item.gitHubUrl && (
                <p>
                  <b>Free sample</b> on{' '}
                  <a href={item.gitHubUrl} rel="noreferrer" target="_blank" className="normal">
                    GitHub
                  </a>
                </p>
              )}
            </div>
          </>
        </TemplateCalloutSection>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        isArchived
        filter
        slug
        name
        tech
        title
        subtitle
        subtitle2
        longTitle
        listTitle
        tags
        isFree
        icons
        previewUrl
        updated(formatString: "MMMM D, YYYY")
        timestamp: updated(formatString: "X")
        hasAbout
        hasCompare
        ratingValue
        ratingCount
        gitHubUrl
        gitHubUrls {
          title
          url
        }
        screenshot {
          childImageSharp {
            gatsbyImageData
          }
        }
        screenshots {
          childImageSharp {
            gatsbyImageData
            id
          }
        }
        hasMultipleColors
        versions {
          title
          subtitle
          icon
        }
        package {
          childMdx {
            body
          }
        }
        structure {
          childMdx {
            body
          }
        }
      }
      body
    }
  }
`

export default Template
