import React from 'react'
import SectionButtons from './sectionButtons'
import TemplateButtons from './templateButtons'
import { templatePath, previewUrl } from '../helpers'

const SectionTemplateButtons = ({ wrap = true, item: { isFree, gitHubUrl, ...item } }) => {
  const links = [
    {
      to: isFree ? gitHubUrl : templatePath(item, 'buy'),
      label: isFree ? 'GitHub' : 'Buy now',
      style: isFree ? 'buttonDefault' : 'buttonDefaultXl',
    },
    {
      to: previewUrl(item),
      label: 'Live preview',
      style: isFree ? 'buttonOutline' : 'buttonOutlineXl',
    },
  ]

  if (wrap) {
    return (
      <SectionButtons>
        <TemplateButtons links={links} />
      </SectionButtons>
    )
  }

  return <TemplateButtons links={links} />
}

export default SectionTemplateButtons
